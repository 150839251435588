import LoadingIndicator from '@component/LoadingIndicator/LoadingIndicator';
import AWS from 'aws-sdk';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import {
  Accordion,
  Button,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Modal,
  Radio,
  Tab,
  TextArea,
} from 'semantic-ui-react';
import api, { apiGo, apis, updateExperimentUser } from '../../../api/index';
import { API_KEY, awsConfig, KEY } from '../../../config';
import { itemUrl } from '../../../constant/imgUrls';
import { reviewOptions } from '../../../constant/reviewResult';
import resizeImage from '../../../global/resizeImage';
import { getBankOptions } from '../../../utils/dropdownOptions';
import { handleChangeSingleFile } from '../../../utils/uploadImage';
import { CartInfo, RetryChallengeInfo } from '../../Shared';
import ValidationRequestModal from '../../ValidationRequestModal';
import AbusingInfo from './AbusingInfo';
import AccountInfo from './AccountInfo/AccountInfo';
import ActivatedRupInfo from './ActivatedRupInfo';
import AllAchievementModal from './AllAchievementModal';
import AllAchievementUrlsModal from './AllAchievementUrlsModal';
import ChallengeInfo from './ChallengeInfo';
import NotificationModal from './NotificationModal/NotificationModal';
import PillOrder from './PillOrder';
import PurchaseChallengeInfo from './PurchaseChallengeInfo';
import RaceOrder from './RaceOrder';
import RemainBalancePerTransaction from './RemainBalancePerTransaction';
import StorePointTransaction from './StorePointTransaction';
import TransactionList from './TransactionList';
import UploadUrlAchievementModal from './UploadUrlAchievementModal';
import UserBasicInfo from './UserBasicInfo';

AWS.config.update({
  region: awsConfig.bucketRegion,
  credentials: new AWS.Credentials({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  }),
  dynamoDbCrc32: false,
});

const ddb = new AWS.DynamoDB({
  apiVersion: '2012-08-10',
});

const type_obj = {
  CHALLENGE_REGISTER: '챌린지 등록',
  CHALLENGE_CANCEL: '챌린지 취소',
  CHALLENGE_RETURN: '챌린지 환급',
  REWARD: '챌린지 상금',
  WITHDRAW_PRIZE: '상금 출금',
  RED: '레드카드 2회 포인트 회수',
  DELETE: '1년 지난 포인트 삭제',
  RECOMMEND: '추천인 포인트',
  EVENT: '이벤트',
  REGISTER_CARD: '캐시충전',
  CHARGE_CARD: '캐시충전',
  REGISTER_TRANSFER: '캐시충전',
  CHARGE_TRANSFER: '캐시충전',
  WITHDRAW_CARD: '캐시출금',
  WITHDRAW_TRANSFER: '캐시출금',
  PRODUCT_PURCHASE: '상품구입',
  PRODUCT_CANCEL: '상품구입취소',
  EXCHANGE_FROM_CASH: '캐시>스토어 전환',
  EXCHANGE_FROM_CASH_CANCEL: '캐시>스토어 전환 취소',
  EXCHANGE_FROM_PRIZE: '상금>스토어 전환',
  OFFER_WALL: '오퍼월',
  COLLABO_VIRAL_LOOP_INVITER: '획득',
  COLLABO_VIRAL_LOOP_INVITEE: '챌린지환급',
  ETC_USE: '기타',
};

const point_type_obj = {
  CHALLENGE_REGISTER: '캐시',
  CHALLENGE_CANCEL: '캐시',
  CHALLENGE_RETURN: '캐시',
  PRODUCT_PURCHASE: '캐시',
  PRODUCT_CANCEL: '캐시',
  EVENT: '상금',
  REWARD: '상금',
  WITHDRAW: '상금',
  RED: '상금',
  DELETE: '상금',
  RECOMMEND: '상금',
  REGISTER_CARD: '카드',
  CHARGE_CARD: '카드',
  REGISTER_TRANSFER: '계좌이체',
  CHARGE_TRANSFER: '계좌이체',
  WITHDRAW_CARD: '카드',
  WITHDRAW_TRANSFER: '계좌이체',
  EXCHANGE_FROM_CASH: '캐시',
  EXCHANGE_FROM_CASH_CANCEL: '캐시',
  EXCHANGE_FROM_PRIZE: '상금',
  OFFER_WALL: '상금',
};

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      badges: [],
      exp: {},
      carts: [],
      recommenders: [],
      recommendee: {},
      isMounted: false,
      cancelModalOpen: false,
      ruc: {},
      reviewModalOpen: false,
      review: '',
      reviewComment: '',
      achievement: {},
      message: '',
      deleteModalOpen: false,
      uploadModalOpen: false,
      uploadUrlModalOpen: false,
      commentModalOpen: false,
      redirect: false,
      checkPgIds: [],
      checkTransferIds: [],
      selectImage: 'custom',
      cashTransaction: {},
      bankOptions: [],
      userChallengesCompleted: {},
      challengeCompletedMounted: false,
      challengeCompletedLoading: false,
      adminCommentModalOpen: false,
      reviewTemplates: [],
      reviewMessageOptions: [],
      reviewDeleteCommentOptions: [
        {
          key: 0,
          message:
            '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n사유 : \n\n인증 규정과 달라 회원님의 인증샷이 정상처리 되지 않았습니다😥 공정한 진행을 위해 아쉽게도 이번 인증샷은 삭제되오니 다음에는 인증 규정을 꼭 지켜주세요!\n\n[인증 규정]\n${authenticationMethod}',
          order: 0,
          text: '삭제 - 푸시발송용',
          value: 0,
        },
        {
          key: 1,
          message:
            '[본 내용은 회원님에게만 보입니다. 다른 의견이 있으신 경우 댓글이 아닌 마이페이지탭의 문의하기를 통해 연락주세요]\n\n[인증 규정]\n${authenticationMethod}',
          order: 1,
          text: '삭제 - 인증처리용',
          value: 1,
        },
      ],
      deleteOptionValue: '',
      reports: [],
      reportModalOpen: false,
      heartModalOpen: false,
      allAchievementFilesModalOpen: false,
      allAchievementUrlsModalOpen: false,
      activeIndex: -1,
      transactionToggleOpen: false,
      challengeAccordion: {},
      challengeAccordionAllOpen: false,
      heartCancelModalOpen: false,
      withdrawPrizeSum: 0,
      notifications: [],
      notificationModalOpen: false,
      cashTransactions: [],
      prizeTransactions: [],
      editUserModalOpen: false,
      deleteAndPassCheckedModalOpen: false,
      checkedAchievementFileIds: [],
      isLoading: false,
      retries: [],
      allAchievementFiles: [],
      allAchievementUrls: [],
      authentications: {},
      badge: {},
      registeredChallenges: [],
      registerCountModalOpen: false,
      networking: false,
      switchModalOpen: false,
      dragItem: null,
      dropItem: null,
      validationModalOpen: false,
      validated: false,
      experimentGroupChangeModalOpen: false,
      experiment: {},
      experimentGroupId: -1,
      experimentGroupOptions: [],
      activeTab: 'CHALLENGE',
      userAddressList: [],
      userSocials: {},
      purchaseChallengeParticipants: [],
      participant: {},
      cancelPurchaseChallengeModalOpen: false,
    };
    this.el = React.createRef();
    this.commentEl = React.createRef();
    this.ref = React.createRef();
    this.isScrolling = false;
    this.clientY = 0;
    this.scrollY = 0;
    this.review = {
      reviewComment: '',
    };
    this.comment = {
      value: '',
      commentCompleted: true,
    };
    this.deleteCommentEl = React.createRef();
    this.deleteComment = {
      value: '',
      commentCompleted: true,
    };
    this.status = {
      cardCancelCompleted: true,
      transferWithdrawCompleted: true,
      prizeWithdrawCompleted: true,
    };
    this.account = {
      bankCode: '',
      bankName: '',
      accountNo: '',
      accountName: '',
      prizePoint: 0,
      declareUserName: '',
      declareUserSsn: '',
    };
    this.date = {
      filterStartDatetime: moment().subtract(30, 'd').format('YYYY-MM-DD'),
      filterEndDatetime: moment().format('YYYY-MM-DD'),
    };
    this.input = {
      smsText: '',
      pushTitle: '',
      pushBody: '',
    };
    this.userInfo = {};
  }

  onMouseDown = (e) => {
    this.isScrolling = true;
    this.clientY = e.clientY;
  };

  onMouseUp = () => {
    this.isScrolling = false;
    this.clientY = 0;
    this.scrollY = 0;
  };

  onMouseMove = (e) => {
    if (this.isScrolling) {
      this.ref.current.scrollTop = this.scrollY + e.clientY - this.clientY;
      this.scrollY = this.scrollY + e.clientY - this.clientY;
    }
  };

  async componentDidMount() {
    const userId = this.props.match.params.id;
    const res = await apis.user.getUser(userId);
    const {
      user,
      badges,
      exp,
      carts,
      experiments,
      userMarketingInfo,
      userSocials,
    } = res;
    user.achievements.forEach((a) => (a.isChecked = false));
    const challengeAccordion = {};
    user.relationUserChallenges.forEach((ruc) => {
      challengeAccordion[ruc.challenge.id] = false;
    });

    const pictureUrl =
      user.pictureUrl === ''
        ? 'https://d246jgzr1jye8u.cloudfront.net/development/challenge/1536807709708362.jpg'
        : user.pictureUrl;
    user.pictureUrl = resizeImage(pictureUrl, 80);

    const cashRes = await apis.user.getUserCashBalance(userId);
    const {
      cardCashBalance,
      transferCashBalance,
      cashTransactions,
      cashAmounts,
    } = cashRes;
    user.account.cardCashBalance = cardCashBalance;
    user.account.transferCashBalance = transferCashBalance;
    user.account.cashTransactions = _.map(cashTransactions, (ct) => {
      ct.currency = 'CASH';

      if (ct.type === 'EVENT') {
        ct.transactionTransferId = ct.transactionUserId;
      }

      return {
        ...ct,
        isChecked: false,
        amount: 0,
      };
    });

    user.account.cashAmounts = cashAmounts;

    this.setState(
      {
        user,
        badges,
        exp,
        carts,
        experiments,
        userMarketingInfo,
        cashTransactions,
        challengeAccordion,
        userSocials,
      },
      () => this.setState({ isMounted: true }),
    );

    const prizeRes = await apis.user.getUserPrizeBalance(userId);
    const { prizeTransactions } = prizeRes;
    prizeTransactions.forEach((t) => (t.currency = 'PRIZE'));
    const withdrawTransactions = _.filter(
      prizeTransactions,
      (pt) => pt.type === 'WITHDRAW_PRIZE',
    );
    const withdrawPrizeSum = -_.sumBy(withdrawTransactions, 'amount');
    this.setState({ withdrawPrizeSum, prizeTransactions });

    // 친구추천 정보 가져오기
    const { recommenders, recommendee } =
      await apis.user.getRecommendInfoByUser(userId);
    // 은행 정보 가져오기
    const bankOptions = await getBankOptions();
    // 유저 주소록 정보 가져오기
    const { userAddressList } = await apis.user.getUserAddressList(userId);
    // 세일즈 부스터 챌린지 참여이력
    const { participants } =
      await apis.purchase.getPurchaseChallengeParticipants(userId);

    this.setState({
      recommenders,
      recommendee,
      bankOptions,
      userAddressList,
      purchaseChallengeParticipants: participants ?? [],
    });

    this.getReviewTemplate();
    this.getNotifications();
    this.getUserRetries();
  }

  getReviewTemplate = () => {
    ddb.scan({ TableName: 'ReviewTemplate' }, (err, data) => {
      if (err) {
        alert(err);
      } else {
        const templates = _.map(data.Items, (item) => {
          return {
            key: parseInt(item.key.N, 10),
            text: item.text.S,
            value: parseInt(item.value.N, 10),
            order: parseInt(item.order.N, 10),
            message: item.message.S,
          };
        });
        const reviewMessageOptions = _.sortBy(templates, ['order']);
        const sortedTemplates = _.map(reviewMessageOptions, (t) => t.message);
        this.setState({
          reviewTemplates: sortedTemplates,
          reviewMessageOptions,
        });
      }
    });
  };

  getNotifications = () => {
    api
      .get('/admin/notifications', {
        params: { userId: this.props.match.params.id },
      })
      .then((res) => {
        const { notifications } = res.data.data;
        this.setState({ notifications });
      });
  };

  getUserRetries = async () => {
    const res = await apis.user.getUserRetryChallenges(
      this.props.match.params.id,
    );
    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }
    const { retries } = res;
    this.setState({ retries });
  };

  showCancelModal = (e) => {
    const { user } = this.state;
    const ruc = _.find(
      user.relationUserChallenges,
      (r) => r.id === parseInt(e.target.value, 10),
    );
    this.setState({ ruc: ruc, cancelModalOpen: true });
  };
  closeCancelModal = () => {
    if (!this.state.isLoading) {
      this.setState({ cancelModalOpen: false });
    }
  };
  registerCancel = () => {
    this.setState({ isLoading: true });
    const { user, ruc } = this.state;
    apiGo
      .post('/challenges/cancel/forced', {
        userIDs: [user.id],
        challengeIDs: [ruc.challengeId],
      })
      .then(() => {
        user.relationUserChallenges = _.filter(
          user.relationUserChallenges,
          (r) => r.id !== parseInt(ruc.id, 10),
        );
        this.setState({ user, cancelModalOpen: false, isLoading: false });
        alert('챌린지 신청 취소가 완료되었습니다.');
      })
      .catch((error) => {
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        );
      });
  };
  showPurchaseChallengeCancelModal = (e) => {
    const { purchaseChallengeParticipants } = this.state;
    const participant = _.find(
      purchaseChallengeParticipants,
      (participant) => participant.id === Number(e.target.value),
    );
    this.setState({ participant, cancelPurchaseChallengeModalOpen: true });
  };
  closePurchaseChallengeCancelModal = () => {
    if (!this.state.isLoading) {
      this.setState({
        participant: {},
        cancelPurchaseChallengeModalOpen: false,
      });
    }
  };
  registerCancelPurchaseChallenge = async () => {
    this.setState({ isLoading: true });
    const { participant, purchaseChallengeParticipants } = this.state;
    await apis.purchase.unregisterPurchaseChallenge(
      participant.purchaseChallengeParticipantId,
      {
        onSuccess: () => {
          this.setState({
            isLoading: false,
            purchaseChallengeParticipants: purchaseChallengeParticipants.filter(
              (purchaseChallengeParticipant) =>
                purchaseChallengeParticipant.id !== participant.id,
            ),
            participant: {},
            cancelPurchaseChallengeModalOpen: false,
          });
          alert('챌린지 신청 취소가 완료되었습니다.');
        },
        onError: (error) => {
          this.setState({ isLoading: false });
          alert(
            '[' +
              error.response.status +
              '] ' +
              error.response.data.userMessage,
          );
        },
      },
    );
  };

  showReviewModal = (e, { name, value }) => {
    this.setState({
      reviewModalOpen: true,
      review: e.target.name,
      achievement: value,
    });
  };
  closeReviewModal = () => this.setState({ reviewModalOpen: false });
  handleChangeMessage = (e, { value }) => {
    const { user, achievement, reviewTemplates } = this.state;
    let reviewComment = reviewTemplates[value];
    reviewComment = reviewComment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${achievement.challenge.authenticationMethod}\n`;
    if (achievement.challenge.authenticationChecklist) {
      authenticationMethodText += achievement.challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    reviewComment = reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.setState({ message: value, reviewComment });
    this.el.current.ref.current.value = reviewComment;
    this.review.reviewComment = reviewComment;
  };
  handleRadioChange = (e, { name, value }) => {
    this.setState({ review: value });
  };
  handleExperimentRadioChange = (e, { value }) => {
    this.setState({ experimentGroupId: value });
  };
  handleChangeReviewComment = (e) => {
    this.review.reviewComment = e.target.value;
  };
  handleReviewResult = (isPushNeeded) => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true }, async () => {
      const { review, achievement, user } = this.state;
      const { reviewComment } = this.review;
      const res = await apis.reviewAchievementFile(
        achievement.achievementFile.id,
        {
          reviewResult: review,
          reviewComment,
          isPushNeeded,
        },
      );
      const { achievement: newAchievement } = res;
      const newAchievements = user.achievements.map((a) => {
        if (a.id === newAchievement.id) {
          a.achievementFile.reviewResult = review;
          return a;
        } else return a;
      });
      this.setState({
        achievements: newAchievements,
        reviewModalOpen: false,
        isLoading: false,
      });
    });
  };

  showDeleteModal = (e, { name, value }) => {
    this.setState({ deleteModalOpen: true, achievement: value }, () => {
      this.deleteCommentEl.current.ref.current.value = this.deleteComment.value;
    });
  };
  closeDeleteModal = () => this.setState({ deleteModalOpen: false });

  showCommentModal = (e, { name, value }) => {
    this.setState({ commentModalOpen: true, achievement: value }, () => {
      if (this.commentEl.current) {
        this.commentEl.current.ref.current.value = this.comment.value;
      }
    });
  };
  closeCommentModal = () => this.setState({ commentModalOpen: false });

  showReportModal = (e, { value }) =>
    this.setState({ reportModalOpen: true, reports: value });
  closeReportModal = () => this.setState({ reportModalOpen: false });

  showUploadModal = (e, { value }) => {
    value.submitDatetime = moment(value.dueStartDate).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.setState({ uploadModalOpen: true, achievement: value });
  };
  closeUploadModal = () => this.setState({ uploadModalOpen: false });

  showUploadUrlModal = (e, { value }, additionalInfo) => {
    value.submitDatetime = moment(value.dueStartDate).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.setState({
      uploadUrlModalOpen: true,
      achievement: {
        ...value,
        snsType: additionalInfo.snsType,
        achievementIndex: additionalInfo.achievementIndex,
      },
    });
  };

  closeUploadUrlModal = () => this.setState({ uploadUrlModalOpen: false });

  showHeartModal = (e, { value }) => {
    value.submitDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    this.setState({ heartModalOpen: true, achievement: value });
  };
  closeHeartModal = () => this.setState({ heartModalOpen: false });

  showHeartCancelModal = (e, { value }) =>
    this.setState({
      heartCancelModalOpen: true,
      achievement: value,
    });
  closeHeartCancelModal = () => this.setState({ heartCancelModalOpen: false });

  showAllAchievementFilesModal = async (e, { value }) => {
    LoadingIndicator.show();
    try {
      const res =
        await apis.achievement.getAchievementFilesByAchievementId(value);

      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      const { achievementFiles: allAchievementFiles } = res;

      if (allAchievementFiles.length === 0) {
        return;
      }

      this.setState({
        allAchievementFilesModalOpen: true,
        allAchievementFiles,
        authentications: {
          authenticationMethod:
            allAchievementFiles[0].challenge.authenticationMethod,
          authenticationChecklist:
            allAchievementFiles[0].challenge.authenticationChecklist,
        },
      });
    } finally {
      LoadingIndicator.hide();
    }
  };

  showAllAchievementUrlsModal = async (e, { value }) => {
    LoadingIndicator.show();

    try {
      const res =
        await apis.achievement.getAchievementFilesByAchievementId(value);

      if (res?.response?.data && 'err' in res?.response?.data) {
        return;
      }

      const { achievementFiles: allAchievementFiles } = res;

      this.setState({
        allAchievementUrlsModalOpen: true,
        allAchievementFiles,
        authentications: {
          authenticationMethod:
            allAchievementFiles[0].challenge.authenticationMethod,
          authenticationChecklist:
            allAchievementFiles[0].challenge.authenticationChecklist,
        },
      });
    } finally {
      LoadingIndicator.hide();
    }
  };
  closeAllAchievementFilesModal = () => {
    this.setState({
      allAchievementFilesModalOpen: false,
      allAchievementFiles: [],
      authentications: {},
    });
  };

  closeAllAchievementUrlsModal = () => {
    this.setState({
      allAchievementUrlsModalOpen: false,
      allAchievementFiles: [],
      authentications: {},
    });
  };

  setDragItem = (achievement) => this.setState({ dragItem: achievement });
  setDropItem = (achievement) => {
    console.log(575, achievement);
    if (this.state.dragItem.id !== achievement.id) {
      this.setState({ dropItem: achievement, switchModalOpen: true });
    }
  };
  closeSwitchModal = () => {
    if (!this.state.isLoading) {
      this.setState({ switchModalOpen: false, dragItem: null, dropItem: null });
    }
  };
  switchAchievementFiles = async () => {
    this.setState({ isLoading: true });
    const { dragItem, dropItem, user } = this.state;
    const dragItemFile = { ...dragItem.achievementFile };
    const dropItemFile = { ...dropItem.achievementFile };
    // 드래그 아이템을 드롭 아이템으로 바꿔치기
    if (dropItem.achievementFile.id !== 0) {
      // 드래그 아이템이 있을 경우
      if (dragItem.achievementFile.id !== 0) {
        await apis.achievement.updateAchievementFileAchievementId(
          dragItem.achievementFile.id,
          dropItem.id,
        );
        user.achievements.forEach((a) => {
          if (a.id === dragItem.id) {
            a.achievementFile = dropItemFile;
          }
        });
      } else {
        // 드래그 아이템이 원래 없는 경우에는 새로 등록한다
        const res = await apis.createAchievementFile(dragItem.id, {
          imageUrl: dropItem.achievementFile.url,
          submitDatetime: moment(
            `${dragItem.dueStartDate} ${dragItem.dueStartTime}`,
          ).format('YYYY-MM-DD HH:mm:ss'),
          userId: user.id,
        });

        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }

        const { achievementFile } = res;

        await apis.deleteAchievementFile(dropItem.achievementFile.id, {
          deleteType: 'DELETE',
          reviewComment: '',
          userId: user.id,
        });

        user.achievements.forEach((a) => {
          if (a.id === dragItem.id) {
            a.achievementFile = {
              ...achievementFile,
              comments: [],
              reports: [],
            };
          } else if (a.id === dropItem.id) {
            a.achievementFile = {};
            a.rate = null;
          }
        });
      }
    }
    // 드롭 아이템을 드래그 아이템으로 바꿔치기
    if (dragItem.achievementFile.id !== 0) {
      // 드롭 아이템이 원래 있는 경우
      if (dropItem.achievementFile.id !== 0) {
        await apis.achievement.updateAchievementFileAchievementId(
          dropItem.achievementFile.id,
          dragItem.id,
        );
        user.achievements.forEach((a) => {
          if (a.id === dropItem.id) {
            a.achievementFile = dragItemFile;
          }
        });
      } else {
        // 드롭 아이템이 원래 없는 경우에는 새로 등록한다
        const res = await apis.createAchievementFile(dropItem.id, {
          imageUrl: dragItem.achievementFile.url,
          submitDatetime: moment(
            `${dropItem.dueStartDate} ${dropItem.dueStartTime}`,
          ).format('YYYY-MM-DD HH:mm:ss'),
          userId: user.id,
        });

        if (res?.response?.data && 'err' in res?.response?.data) {
          return;
        }

        const { achievementFile } = res;

        await apis.deleteAchievementFile(dragItem.achievementFile.id, {
          deleteType: 'DELETE',
          reviewComment: '',
          userId: user.id,
        });

        user.achievements.forEach((a) => {
          if (a.id === dropItem.id) {
            a.achievementFile = {
              ...achievementFile,
              comments: [],
              reports: [],
            };
          } else if (a.id === dragItem.id) {
            a.achievementFile = {};
            a.rate = null;
          }
        });
      }
    }
    this.setState({ user, isLoading: false }, () => {
      alert('변경이 완료되었습니다.');
      this.closeSwitchModal();
    });
  };

  _deleteAchievementFile = (deleteType) => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true }, async () => {
      const { achievement, user } = this.state;
      const res = await apis.deleteAchievementFile(
        achievement.achievementFile.id,
        {
          deleteType,
          reviewComment: this.deleteComment.value,
          userId: user.id,
        },
      );
      if (res?.response?.data?.err) {
        this.setState({ deleteModalOpen: false });
        return;
      }
      const { achievementIds } = res;
      this.updateDeletedAchievement(achievementIds, deleteType);
      this.setState({ isLoading: false });
    });
  };

  updateDeletedAchievement = (achievementIds, deleteType) => {
    const { user } = this.state;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        if (['DELETE', 'PUSH'].includes(deleteType)) {
          a.achievementFile = {};
        } else {
          a.achievementFile.url =
            'https://d246jgzr1jye8u.cloudfront.net/development/achievement/success.jpg';
        }
        a.transactionItem = null;
      }
    });
    this.setState({ user, deleteModalOpen: false, achievement: {} }, () => {
      alert('인증샷 삭제가 완료되었어요');
    });
  };
  commentAchievement = async (e, { name }) => {
    if (!this.comment.commentCompleted) {
      alert('발송 진행 중입니다.');
      return;
    }
    this.comment.commentCompleted = false;
    const { achievement } = this.state;
    const { value } = this.comment;
    const res = await apis.commentAchievementFile(
      achievement.achievementFile.id,
      {
        reviewComment: value,
        sendType: name,
      },
    );
    if (res?.response?.data?.err) {
      return;
    }
    this.setState({ commentModalOpen: false });
    this.comment.commentCompleted = true;
    alert('댓글 및 문자 발송이 완료되었습니다.');
  };
  handleChangeCommentTemplate = (e, { value }) => {
    const { user, achievement, reviewTemplates } = this.state;
    let reviewComment = reviewTemplates[value];
    reviewComment = reviewComment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${achievement.challenge.authenticationMethod}\n`;
    if (achievement.challenge.authenticationChecklist) {
      authenticationMethodText += achievement.challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    reviewComment = reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.setState({ message: value });
    this.comment.value = reviewComment;
    this.commentEl.current.ref.current.value = reviewComment;
  };
  handleChangeComment = (e) => {
    this.comment.value = e.target.value;
  };
  handleChangeDeleteCommentTemplate = (e, { value }) => {
    const {
      reviewDeleteCommentOptions,
      user,
      achievement: { challenge },
    } = this.state;
    let reviewComment = reviewDeleteCommentOptions[value].message;
    reviewComment = reviewComment.replace(/\${nickname}/g, user.nickname);
    let authenticationMethodText = `${challenge.authenticationMethod}\n`;
    if (challenge.authenticationChecklist) {
      authenticationMethodText += challenge.authenticationChecklist
        ?.map((item) => '* ' + item)
        .join('\n');
    }
    reviewComment = reviewComment.replace(
      /\${authenticationMethod}/g,
      authenticationMethodText,
    );
    this.setState({ deleteOptionValue: value });
    this.deleteComment.value = reviewComment;
    this.deleteCommentEl.current.ref.current.value = reviewComment;
  };
  handleChangeDeleteComment = (e) => {
    this.deleteComment.value = e.target.value;
  };

  showDeleteAndPassCheckedModal = () => {
    this.setState({ deleteAndPassCheckedModalOpen: true });
  };
  closeDeleteAndPassCheckedModal = () =>
    this.setState({ deleteAndPassCheckedModalOpen: false });
  handleAchievementCheckbox = (e, { name, value }) => {
    const { user, checkedAchievementFileIds } = this.state;
    user.achievements.forEach((a) => {
      if (a.achievementFile.id === value) {
        a.isChecked = !a.isChecked;
      }
    });
    let newCheckedAchievementFileIds;
    if (checkedAchievementFileIds.includes(value)) {
      newCheckedAchievementFileIds = checkedAchievementFileIds.filter(
        (id) => id !== value,
      );
    } else {
      newCheckedAchievementFileIds = [...checkedAchievementFileIds, value];
    }
    this.setState({
      user,
      checkedAchievementFileIds: newCheckedAchievementFileIds,
    });
  };

  deleteAndPassCheckedImages = async () => {
    const { checkedAchievementFileIds, isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const res = await apis.achievement.bulkUpdateAchievementFileReviewResult({
      achievementFileIds: checkedAchievementFileIds,
      reviewComment: 'DELETE_AND_PASS',
      reviewComment: '',
      isPushNeeded: false,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    alert(
      `선택한 인증샷 ${checkedAchievementFileIds.length}개 삭제가 완료되었습니다.`,
    );
  };

  handleAchievement = (e) => {
    const { achievement } = this.state;
    achievement[e.target.name] = e.target.value;
    this.setState({ achievement });
  };
  handleChangeSingleFile = async (e) => {
    const achievement = await handleChangeSingleFile(e, this.state.achievement);
    this.setState({ achievement });
  };
  handleSelectImageChange = (e, { name, value }) => {
    const { achievement } = this.state;
    achievement.url =
      value === 'success'
        ? 'https://d246jgzr1jye8u.cloudfront.net/development/achievement/success.jpg'
        : '';
    this.setState({ selectImage: value, achievement });
  };

  uploadAchievementFile = async () => {
    const { achievement, user } = this.state;
    const res = await apis.createAchievementFile(achievement.id, {
      imageUrl: achievement.url,
      submitDatetime: achievement.submitDatetime,
      userId: user.id,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { achievementFile } = res;

    const newAchievements = user.achievements.map((a) => {
      if (a.id === achievement.id) {
        a.achievementFile = {
          ...achievementFile,
          comments: [],
          reports: [],
        };
        return a;
      } else return a;
    });

    this.setState({
      achievements: newAchievements,
      uploadModalOpen: false,
      selectImage: 'custom',
    });
  };

  uploadAchievementUrl = async ({ postUrl, submitDatetime }) => {
    const { achievement, user } = this.state;
    const { snsType, achievementIndex } = achievement;
    if (!snsType || !achievementIndex) return;

    const res = await apis.createAchievementFile(achievement.id, {
      submitDatetime,
      userId: user.id,
      postUrl,
      sns: snsType,
      achievementIndex,
    });

    if (res?.response?.data && 'err' in res?.response?.data) {
      return;
    }

    const { achievementFile } = res;

    const newAchievements = user.achievements.map((a) => {
      if (a.id === achievement.id) {
        a.achievementFile = {
          ...achievementFile,
          comments: [],
          reports: [],
        };
        return a;
      } else return a;
    });

    this.setState({
      achievements: newAchievements,
      uploadUrlModalOpen: false,
    });
  };

  useHeart = async () => {
    const { achievement, user } = this.state;
    const res = await apis.useAchievementItem(achievement.id);
    if (res?.response?.data?.err) {
      return;
    }
    const { achievementIds } = res;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        a.achievementFile = {
          url: itemUrl,
          comments: [],
          reports: [],
          createdAt: moment(),
          submitDatetime: moment(
            `${achievement.dueStartDate} ${achievement.dueStartTime}`,
          ),
        };
        a.transactionItem = {
          createdAt: new Date(),
        };
      }
    });
    this.setState({ user, heartModalOpen: false });
    alert('하트 사용이 완료되었습니다.');
  };
  cancelHeart = async () => {
    const { achievement, user } = this.state;
    const res = await apis.deleteTransactionItem(achievement.id, {
      userId: user.id,
    });
    if (res?.response?.data?.err) {
      this.setState({ heartCancelModalOpen: false });
      return;
    }
    alert('하트 사용 취소가 완료되었습니다.');
    const { achievementIds } = res;
    user.achievements.forEach((a) => {
      if (achievementIds.includes(a.id)) {
        a.achievementFile = {};
        delete a['transactionItem'];
      }
    });
    this.setState({ user, heartCancelModalOpen: false });
  };

  handleCheckbox = async (e, { name, value }) => {
    const { user, checkPgIds, checkTransferIds } = this.state;
    const cashTransaction =
      name === 'transactionPg'
        ? _.find(
            user.account.cashTransactions,
            (ct) => ct.transactionPgId === value,
          )
        : _.find(
            user.account.cashTransactions,
            (ct) => ct.transactionTransferId === value,
          );
    cashTransaction.isChecked = !cashTransaction.isChecked;
    if (['REGISTER_CARD', 'CHARGE_CARD'].includes(cashTransaction.type)) {
      if (cashTransaction.isChecked && !checkPgIds.includes(value)) {
        checkPgIds.push(value);
        const availCardBalance = await this.checkTotalAmount('PG', checkPgIds);
        if (availCardBalance > 0) {
          cashTransaction.amount =
            availCardBalance > cashTransaction.deposit
              ? cashTransaction.deposit
              : availCardBalance;
          cashTransaction.maxAmount = cashTransaction.amount;
        } else {
          const index = checkPgIds.indexOf(value);
          checkPgIds.splice(index, 1);
          cashTransaction.isChecked = !cashTransaction.isChecked;
          cashTransaction.maxAmount = 0;
          cashTransaction.amount = 0;
          alert('카드 캐시포인트 잔액을 초과하였습니다.');
        }
      } else if (!cashTransaction.isChecked && checkPgIds.includes(value)) {
        cashTransaction.amount = 0;
        cashTransaction.maxAmount = 0;
        const index = checkPgIds.indexOf(value);
        checkPgIds.splice(index, 1);
      }
    } else {
      if (cashTransaction.isChecked && !checkTransferIds.includes(value)) {
        checkTransferIds.push(value);
        const availTransferBalance = await this.checkTotalAmount(
          'TRANSFER',
          checkTransferIds,
        );
        if (availTransferBalance > 0) {
          cashTransaction.amount =
            availTransferBalance > cashTransaction.deposit
              ? cashTransaction.deposit
              : availTransferBalance;
          cashTransaction.maxAmount = cashTransaction.amount;
        } else {
          const index = checkTransferIds.indexOf(value);
          checkTransferIds.splice(index, 1);
          cashTransaction.isChecked = !cashTransaction.isChecked;
          cashTransaction.amount = 0;
          cashTransaction.maxAmount = 0;
          alert('계좌이체 캐시포인트 잔액을 초과하였습니다.');
        }
      } else if (
        !cashTransaction.isChecked &&
        checkTransferIds.includes(value)
      ) {
        cashTransaction.amount = 0;
        cashTransaction.maxAmount = 0;
        const index = checkTransferIds.indexOf(value);
        checkTransferIds.splice(index, 1);
      }
    }
    this.setState({ user, checkPgIds, checkTransferIds });
  };

  checkTotalAmount = (category, transactionIds) => {
    const { user } = this.state;
    let totalAmount = 0;
    if (category === 'PG') {
      _.map(user.account.cashTransactions, (ct) => {
        if (transactionIds.includes(ct.transactionPgId))
          totalAmount += ct.amount;
      });
      return user.account.cardCashBalance - totalAmount;
    } else {
      _.map(user.account.cashTransactions, (ct) => {
        if (transactionIds.includes(ct.transactionTransferId))
          totalAmount += ct.amount;
      });
      return user.account.transferCashBalance - totalAmount;
    }
  };

  handleCardAmount = (e, { name, value }) => {
    const { user } = this.state;
    const cashTransaction = _.find(
      user.account.cashTransactions,
      (ct) => ct.transactionPgId === name,
    );
    if (value > cashTransaction.maxAmount)
      cashTransaction.amount = cashTransaction.maxAmount;
    else
      cashTransaction.amount =
        parseInt(value, 10) > 0 ? parseInt(value, 10) : 0;
    this.setState({ user });
  };
  handleTransferAmount = (e, { name, value }) => {
    const { user } = this.state;
    const cashTransaction = _.find(
      user.account.cashTransactions,
      (ct) => ct.transactionTransferId === name,
    );
    if (value > cashTransaction.maxAmount)
      cashTransaction.amount = cashTransaction.maxAmount;
    else
      cashTransaction.amount =
        parseInt(value, 10) > 0 ? parseInt(value, 10) : 0;
    this.setState({ user });
  };
  cancelCard = (pgCheck) => {
    if (!this.status.cardCancelCompleted) {
      alert('취소 진행 중입니다.');
      return;
    }
    this.status.cardCancelCompleted = false;
    let key = CryptoJS.enc.Utf8.parse(KEY);
    const { user, checkPgIds } = this.state;
    const cardTransactions = _.filter(user.account.cashTransactions, (ct) =>
      checkPgIds.includes(ct.transactionPgId),
    );
    let totalAmount = 0;
    const transactionPgList = _.map(cardTransactions, (ct) => {
      totalAmount -= parseInt(ct.amount, 10);
      return {
        transactionPgId: ct.transactionPgId,
        orderNo: ct.orderNo,
        amount: -ct.amount,
        category: ct.amount === ct.total ? 'ALL' : 'PARTIAL',
        pgcode: ct.pgcode,
      };
    });
    const encryptObj = {
      pointType: 'CARD',
      accountId: user.account.id,
      userId: user.id,
      amount: totalAmount,
      transactionPgList: transactionPgList,
    };
    const encrypted = this.encrypt(JSON.stringify(encryptObj), key);
    let hashed = CryptoJS.SHA256(
      user.account.id.toString() +
        totalAmount.toString() +
        user.id.toString() +
        API_KEY.toString(),
    );
    api
      .post('/admin/transaction-users/withdraw/cash', {
        encrypted: encrypted,
        hashed: hashed.toString(),
        pgCheck,
      })
      .then((response) => {
        console.log(response.data.data);
        _.forEach(cardTransactions, (ct) => (ct.deposit -= ct.amount));
        this.setState({ user });
        alert('카드취소가 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      )
      .finally(() => {
        this.status.cardCancelCompleted = true;
      });
  };

  withdraw = () => {
    if (!this.status.transferWithdrawCompleted) {
      alert('취소 진행 중입니다.');
      return;
    }
    this.status.transferWithdrawCompleted = false;
    let key = CryptoJS.enc.Utf8.parse(KEY);
    const { user, checkTransferIds } = this.state;
    const transferTransactions = _.filter(user.account.cashTransactions, (ct) =>
      checkTransferIds.includes(ct.transactionTransferId),
    );
    let totalAmount = 0;
    const transactionTransferList = _.map(transferTransactions, (tt) => {
      totalAmount -= parseInt(tt.amount, 10);
      const item = {
        orderNo: tt.orderNo,
        amount: -tt.amount,
        category: tt.amount === tt.deposit ? 'ALL' : 'PARTIAL',
      };

      if (tt.type !== 'EVENT') {
        item['transactionTransferId'] = tt.transactionTransferId;
      }

      return item;
    });
    const encryptObj = {
      pointType: 'TRANSFER',
      accountId: user.account.id,
      userId: user.id,
      amount: totalAmount,
      bankCode: this.account.bankCode,
      bankName: this.account.bankName,
      accountNo: this.account.accountNo,
      accountName: this.account.accountName,
      transactionTransferList: transactionTransferList,
    };
    const encrypted = this.encrypt(JSON.stringify(encryptObj), key);
    let hashed = CryptoJS.SHA256(
      user.account.id.toString() +
        totalAmount.toString() +
        user.id.toString() +
        API_KEY.toString(),
    );
    api
      .post('/admin/transaction-users/withdraw/cash', {
        encrypted: encrypted,
        hashed: hashed.toString(),
      })
      .then((response) => {
        _.forEach(transferTransactions, (tt) => (tt.deposit -= tt.amount));
        this.setState({ user });
        this.status.transferWithdrawCompleted = true;
        this.account = {
          bankCode: '',
          bankName: '',
          accountNo: '',
          accountName: '',
          prizePoint: 0,
          declareUserName: '',
          declareUserSsn: '',
        };
        alert('출금이 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  withdrawPrize = () => {
    if (!this.status.prizeWithdrawCompleted) {
      alert('취소 진행 중입니다.');
      return;
    }
    this.status.prizeWithdrawCompleted = false;
    let key = CryptoJS.enc.Utf8.parse(KEY);
    const { user } = this.state;
    const {
      bankCode,
      bankName,
      accountNo,
      accountName,
      prizePoint,
      declareUserName,
      declareUserSsn,
    } = this.account;
    const encryptObj = {
      pointType: 'TRANSFER',
      accountId: user.account.id,
      userId: user.id,
      bankCode: bankCode,
      bankName: bankName,
      bankAccountNumber: accountNo,
      userName: accountName,
      cashPoint: 0,
      prizePoint: -prizePoint,
      declareUserName: declareUserName,
      declareUserSsn: declareUserSsn,
    };
    const encrypted = this.encrypt(JSON.stringify(encryptObj), key);
    let hashed = CryptoJS.SHA256(
      user.account.id.toString() +
        bankCode.toString() +
        accountNo.toString() +
        accountName.toString() +
        '0' +
        (-prizePoint).toString() +
        API_KEY.toString(),
    );
    api
      .post('/admin/transaction-users', {
        encrypted: encrypted,
        hashed: hashed.toString(),
      })
      .then(() => {
        user.account.prizePoint -= prizePoint;
        this.setState({ user });
        this.account = {
          bankCode: '',
          bankName: '',
          accountNo: '',
          accountName: '',
          prizePoint: 0,
          declareUserName: '',
          declareUserSsn: '',
        };
        this.status.prizeWithdrawCompleted = true;
        alert('출금이 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  handleBankChange = (_, { value }) => {
    const { bankOptions } = this.state;
    const bank = bankOptions.find((o) => o.value === value);
    this.account.bankCode = bank.value;
    this.account.bankName = bank.text;
  };

  handleAccountChange = (e, { name, value }) => {
    this.account[name] = value;
  };

  encrypt = (msgString, key) => {
    console.log(msgString, key);
    let iv = CryptoJS.lib.WordArray.random(16);
    let encrypted = CryptoJS.AES.encrypt(msgString, key, {
      iv: iv,
    });
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  };

  getCompletedChallenges = () => {
    const { user } = this.state;
    const { filterStartDatetime, filterEndDatetime } = this.date;
    this.setState({ challengeCompletedLoading: true });
    api
      .get(
        '/admin/users/' + this.props.match.params.id + '/challenges/completed',
        {
          params: {
            filterStartDatetime: filterStartDatetime,
            filterEndDatetime: filterEndDatetime,
          },
        },
      )
      .then((response) => {
        const userChallengesCompleted = response.data.data.user;
        user.commentAchievementFiles = user.commentAchievementFiles.concat(
          userChallengesCompleted.commentAchievementFiles,
        );
        this.setState({
          user,
          userChallengesCompleted,
          challengeCompletedMounted: true,
          challengeCompletedLoading: false,
        });
      });
  };

  showAdminCommentModal = (e, { value }) =>
    this.setState({ adminCommentModalOpen: true, adminComments: value });
  closeAdminCommentModal = () =>
    this.setState({ adminCommentModalOpen: false });

  handleDate = (e, { name, value }) => {
    this.date[name] = value;
  };

  handleChangeInput = (e) => {
    this.input[e.target.name] = e.target.value;
  };
  sendSms = () => {
    const { user } = this.state;
    const { smsText } = this.input;
    let phones = [user.phone];
    api
      .post('/admin/users/sms', { phones: phones, message: smsText })
      .then(() => {
        alert('문자 발송이 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };
  sendPush = () => {
    const { user } = this.state;
    const { pushTitle, pushBody } = this.input;
    api
      .post('/push-results/admin', {
        startUserId: user.id,
        endUserId: user.id,
        pushTitle,
        pushBody,
      })
      .then(() => {
        alert('푸시 발송이 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  handleChangeUserInfo = (e) => {
    this.userInfo[e.target.name] = e.target.value;
  };
  handleChangeUserInfoRadio = (e, { name, value }) => {
    this.userInfo[name] = value;
  };
  clearUserExp = () => {
    const { user } = this.state;
    api
      .put(`/users/${user.id}/exp`, {
        ability: 0,
        asset: 0,
        relation: 0,
        health: 0,
        life: 0,
        hobby: 0,
      })
      .then((response) => {
        const exp = response.data.data.userExp;
        this.setState({ exp });
        alert('초기화가 완료되었습니다.');
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleChallengeAccordion = (e, titleProps) => {
    const { index: challengeId } = titleProps;
    const { challengeAccordion } = this.state;
    challengeAccordion[challengeId] = !challengeAccordion[challengeId];
    this.setState({ challengeAccordion });
  };

  changeChallengeAccordionAllOpen = () => {
    const { challengeAccordion } = this.state;
    const challengeAccordionAllOpen = !this.state.challengeAccordionAllOpen;
    Object.keys(challengeAccordion).forEach((key) => {
      challengeAccordion[key] = challengeAccordionAllOpen;
    });
    this.setState({ challengeAccordionAllOpen, challengeAccordion });
  };

  updateUserInfo = () => {
    api
      .put('/admin/users/' + this.state.user.id, this.userInfo)
      .then(() => {
        const newUser = { ...this.state.user };
        Object.keys(this.userInfo).forEach((key) => {
          newUser[key] = this.userInfo[key];
        });
        this.userInfo = {};
        this.setState({ user: newUser, editUserModalOpen: false });
      })
      .catch((error) =>
        alert(
          '[' + error.response.status + '] ' + error.response.data.userMessage,
        ),
      );
  };

  deleteCart = (cartId) => {
    api
      .delete(`/cart/${cartId}`)
      .then(() => {
        alert('삭제가 완료되었습니다.');
        const carts = this.state.carts.filter((c) => c.id !== cartId);
        this.setState({ carts });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  updateUserBadge = () => {
    apiGo
      .post('/badges/create', { userId: this.state.user.id })
      .then((res) => {
        const { badges, newRubs } = res.data.data;
        if (newRubs && newRubs.length > 0) {
          alert(
            `[${newRubs
              .map((rub) => rub.name)
              .join(', ')}] 배지를 새롭게 획득하셨습니다.`,
          );
          this.setState({ badges });
        } else {
          alert('새롭게 획득한 배지가 없습니다.');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  syncUserExp = () => {
    api
      .post(`/users/${this.state.user.id}/exp/sync`)
      .then((res) => {
        const { userExp } = res.data.data;
        this.setState({ exp: userExp });
        alert('동기화 작업이 완료되었습니다.');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  updateExperimentUser = async () => {
    const { user, experiments, experiment, experimentGroupId } = this.state;
    await updateExperimentUser(user.id, {
      experimentId: experiment.id,
      groupId: experimentGroupId,
    });
    const _experiments = experiments.map((e) => {
      if (e.id === experiment.id) e.groupId = experimentGroupId;
      return e;
    });
    alert('실험군 변경이 완료되었습니다.');
    this.setState({
      experimentGroupChangeModalOpen: false,
      experiments: _experiments,
      experimentGroupOptions: [],
      experiment: {},
      experimentGroupId: -1,
    });
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  showRegisterCountModal = (badge) => {
    this.setState({ networking: true, registerCountModalOpen: true });
    if (badge.goalIds) {
      let goalIds = [];
      if (badge.category === 'CHALLENGE') {
        goalIds = badge.goalIds;
      } else if (badge.category === 'ROUTINE') {
        badge.goalIds.forEach((g) => {
          g.goalIds.forEach((gId) => goalIds.push(gId));
        });
      }
      api
        .get(`/users/${this.state.user.id}/challenges/registered`, {
          params: {
            goalIds: JSON.stringify(goalIds),
            minAchievementRate: 0.85,
            isResultReceived: true,
            minRegisterDate: badge.createdAt,
          },
        })
        .then((res) => {
          const { challenges } = res.data.data;
          this.setState({ registeredChallenges: challenges, badge });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => this.setState({ networking: false }));
    }
  };
  closeRegisterCountModal = () =>
    this.setState({ registerCountModalOpen: false, registeredChallenges: [] });

  showExperimentGroupChangeModal = (experiment) => {
    const experimentGroupOptions = experiment.variants?.map((item, idx) => ({
      key: item.name,
      text: `${item.name} (${item.weight}%)`,
      value: idx,
    }));
    this.setState({
      experimentGroupChangeModalOpen: true,
      experimentGroupOptions,
      experiment,
      experimentGroupId: experiment.groupId,
    });
  };
  closeExperimentGroupChangeModal = () =>
    this.setState({
      experimentGroupChangeModalOpen: false,
      experimentGroupOptions: [],
      experiment: {},
      experimentGroupId: -1,
    });

  showNotificationModal = () => this.setState({ notificationModalOpen: true });
  closeNotificationModal = () =>
    this.setState({ notificationModalOpen: false });

  showEditUserModal = () => this.setState({ editUserModalOpen: true });
  closeEditUserModal = () => this.setState({ editUserModalOpen: false });

  showValidationModal = () => this.setState({ validationModalOpen: true });
  closeValidationModal = () => this.setState({ validationModalOpen: false });
  setValidated = (validated) => {
    this.setState({ validated });
    this.closeValidationModal();
  };

  render() {
    const {
      user,
      userSocials,
      badges,
      exp,
      recommenders,
      recommendee,
      experiments,
      userMarketingInfo,
      isMounted,
      cancelModalOpen,
      ruc,
      reviewModalOpen,
      review,
      message,
      deleteModalOpen,
      uploadModalOpen,
      uploadUrlModalOpen,
      achievement,
      selectImage,
      checkPgIds,
      checkTransferIds,
      commentModalOpen,
      bankOptions,
      userChallengesCompleted,
      challengeCompletedMounted,
      challengeCompletedLoading,
      adminComments,
      adminCommentModalOpen,
      reviewMessageOptions,
      reviewDeleteCommentOptions,
      deleteOptionValue,
      reports,
      reportModalOpen,
      heartModalOpen,
      activeIndex,
      heartCancelModalOpen,
      allAchievementFilesModalOpen,
      allAchievementUrlsModalOpen,
      withdrawPrizeSum,
      notifications,
      notificationModalOpen,
      cashTransactions,
      prizeTransactions,
      editUserModalOpen,
      deleteAndPassCheckedModalOpen,
      checkedAchievementFileIds,
      isLoading,
      retries,
      allAchievementFiles,
      authentications,
      challengeAccordion,
      challengeAccordionAllOpen,
      registeredChallenges,
      registerCountModalOpen,
      experimentGroupChangeModalOpen,
      experiment,
      experimentGroupId,
      experimentGroupOptions,
      badge,
      networking,
      carts,
      switchModalOpen,
      dragItem,
      dropItem,
      validationModalOpen,
      validated,
      userAddressList,
      transactionToggleOpen,
      participant,
      purchaseChallengeParticipants,
      cancelPurchaseChallengeModalOpen,
    } = this.state;
    const { filterStartDatetime, filterEndDatetime } = this.date;

    if (!isMounted) {
      return <Loader active inline="centered" />;
    }

    let allTransactions = _.orderBy(
      _.concat(cashTransactions, prizeTransactions),
      ['createdAt'],
      ['desc'],
    );
    allTransactions = _.filter(allTransactions, (t) => !!type_obj[t.type]);

    return (
      <div
        ref={this.ref}
        style={{ margin: 40 }}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
      >
        <UserBasicInfo
          user={user}
          userSocials={userSocials}
          badges={badges}
          exp={exp}
          recommenders={recommenders}
          recommendee={recommendee}
          experiments={experiments}
          userMarketingInfo={userMarketingInfo}
          handleChangeInput={this.handleChangeInput}
          sendSms={this.sendSms}
          sendPush={this.sendPush}
          clearUserExp={this.clearUserExp}
          activeIndex={activeIndex}
          handleAccordionClick={this.handleAccordionClick}
          showVerifyModal={this.showVerifyModal}
          cancelVerify={this.cancelVerify}
          editUserModalOpen={editUserModalOpen}
          showEditUserModal={this.showEditUserModal}
          closeEditUserModal={this.closeEditUserModal}
          handleChangeUserInfo={this.handleChangeUserInfo}
          handleChangeUserInfoRadio={this.handleChangeUserInfoRadio}
          updateUserInfo={this.updateUserInfo}
          updateUserBadge={this.updateUserBadge}
          syncUserExp={this.syncUserExp}
          updateExperimentUser={this.updateExperimentUser}
          registeredChallenges={registeredChallenges}
          registerCountModalOpen={registerCountModalOpen}
          experimentGroupChangeModalOpen={experimentGroupChangeModalOpen}
          badge={badge}
          networking={networking}
          showRegisterCountModal={this.showRegisterCountModal}
          closeRegisterCountModal={this.closeRegisterCountModal}
          showExperimentGroupChangeModal={this.showExperimentGroupChangeModal}
          closeExperimentGroupChangeModal={this.closeExperimentGroupChangeModal}
          userAddressList={userAddressList}
        />

        <Header
          as="h5"
          style={{ cursor: 'pointer' }}
          onClick={this.showNotificationModal}
        >
          알림 내역 보기
        </Header>
        <NotificationModal
          notificationModalOpen={notificationModalOpen}
          closeNotificationModal={this.closeNotificationModal}
          notifications={notifications}
        />

        <Modal
          size="tiny"
          open={experimentGroupChangeModalOpen}
          onClose={this.closeExperimentGroupChangeModal}
        >
          <Dimmer active={networking}>
            <Loader active inline="centered" />
          </Dimmer>
          <Modal.Header>실험군 정보 변경</Modal.Header>
          <Modal.Content>
            <h5>{experiment.name}</h5>
            <div>{experiment.description}</div>
            <Dropdown
              placeholder="그룹을 선택해주세요."
              fluid
              search
              selection
              clearable
              name="groupId"
              options={experimentGroupOptions}
              value={experimentGroupId}
              onChange={this.handleExperimentRadioChange}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              content="변경"
              onClick={this.updateExperimentUser}
            />
            <Button
              color="black"
              content="닫기"
              onClick={this.closeExperimentGroupChangeModal}
            />
          </Modal.Actions>
        </Modal>

        <Divider hidden />
        <Divider hidden />

        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: '챌린지',
              render: () => (
                <Tab.Pane
                  onClick={() => this.setState({ activeTab: 'CHALLENGE' })}
                  attached={true}
                >
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <ChallengeInfo
                    user={user}
                    title={'현재 진행중인 챌린지 참가이력'}
                    showCancelModal={this.showCancelModal}
                    showReviewModal={this.showReviewModal}
                    showDeleteModal={this.showDeleteModal}
                    showCommentModal={this.showCommentModal}
                    showUploadModal={this.showUploadModal}
                    showUploadUrlModal={this.showUploadUrlModal}
                    getCompletedChallenges={this.getCompletedChallenges}
                    showAdminCommentModal={this.showAdminCommentModal}
                    showReportModal={this.showReportModal}
                    filterStartDatetime={filterStartDatetime}
                    filterEndDatetime={filterEndDatetime}
                    handleDate={this.handleDate}
                    showHeartModal={this.showHeartModal}
                    showHeartCancelModal={this.showHeartCancelModal}
                    showAllAchievementFilesModal={
                      this.showAllAchievementFilesModal
                    }
                    showAllAchievementUrlsModal={
                      this.showAllAchievementUrlsModal
                    }
                    handleCheckbox={this.handleAchievementCheckbox}
                    showDeleteAndPassCheckedModal={
                      this.showDeleteAndPassCheckedModal
                    }
                    challengeAccordion={challengeAccordion}
                    handleChallengeAccordion={this.handleChallengeAccordion}
                    challengeAccordionAllOpen={challengeAccordionAllOpen}
                    changeChallengeAccordionAllOpen={
                      this.changeChallengeAccordionAllOpen
                    }
                    setDragItem={this.setDragItem}
                    setDropItem={this.setDropItem}
                  />
                  <Modal
                    size="tiny"
                    open={cancelModalOpen}
                    onClose={this.closeCancelModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>
                    <Modal.Header>챌린지 신청 취소</Modal.Header>
                    <Modal.Content>
                      {!_.isEmpty(ruc) && (
                        <p>
                          {ruc.id} {ruc.challenge.title}
                        </p>
                      )}
                      <p>위의 챌린지 신청을 취소 하시겠습니까?</p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="신청취소"
                        onClick={this.registerCancel}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeCancelModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={reviewModalOpen}
                    onClose={this.closeReviewModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>

                    <Modal.Header>인증샷 리뷰하기</Modal.Header>
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <label>메세지 템플릿</label>
                          <Dropdown
                            placeholder="템플릿을 선택하세요."
                            fluid
                            search
                            selection
                            name="messageId"
                            value={message}
                            options={reviewMessageOptions}
                            onChange={this.handleChangeMessage}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>리뷰 결과</label>
                          <Dropdown
                            placeholder="리뷰 결과를 선택하세요.."
                            name="review"
                            selection
                            options={reviewOptions}
                            value={review}
                            onChange={this.handleRadioChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>코멘트</label>
                          <TextArea
                            ref={this.el}
                            rows={6}
                            name="reviewComment"
                            onChange={this.handleChangeReviewComment}
                          />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="제출"
                        onClick={() => this.handleReviewResult(false)}
                      />
                      <Button
                        color="blue"
                        content="제출(푸시)"
                        onClick={() => this.handleReviewResult(true)}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeReviewModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={deleteModalOpen}
                    onClose={this.closeDeleteModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>

                    <Modal.Header>인증샷 삭제</Modal.Header>
                    <Modal.Content>
                      인증샷 삭제를 하시겠습니까?
                      <br />
                      인증기간 내 인증샷 삭제의 경우 재인증이 가능합니다.
                      <Form>
                        <Form.Field>
                          <label>메세지 템플릿</label>
                          <Dropdown
                            placeholder="템플릿을 선택하세요."
                            fluid
                            search
                            selection
                            name="messageId"
                            value={deleteOptionValue}
                            options={reviewDeleteCommentOptions}
                            onChange={this.handleChangeDeleteCommentTemplate}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>댓글 내용을 작성해주세요.</label>
                          <TextArea
                            ref={this.deleteCommentEl}
                            rows={20}
                            onChange={this.handleChangeDeleteComment}
                          />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="삭제"
                        onClick={() => this._deleteAchievementFile('DELETE')}
                      />
                      <Button
                        color="blue"
                        content="삭제(푸시발송)"
                        onClick={() => this._deleteAchievementFile('PUSH')}
                      />
                      <Button
                        color="blue"
                        content="삭제(성공처리)"
                        onClick={() =>
                          this._deleteAchievementFile('DELETE_AND_PASS')
                        }
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeDeleteModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={commentModalOpen}
                    onClose={this.closeCommentModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>
                    <Modal.Header>인증샷 경고 댓글</Modal.Header>
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <label>메세지 템플릿</label>
                          <Dropdown
                            placeholder="템플릿을 선택하세요."
                            fluid
                            search
                            selection
                            name="messageId"
                            value={message}
                            options={reviewMessageOptions}
                            onChange={this.handleChangeCommentTemplate}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>댓글 내용을 작성해주세요.</label>
                          <TextArea
                            ref={this.commentEl}
                            rows={20}
                            onChange={this.handleChangeComment}
                          />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="yellow"
                        content="경고처리(푸시)"
                        name="WARN"
                        onClick={this.commentAchievement}
                      />
                      <Button
                        color="blue"
                        content="댓글작성(푸시)"
                        name="PUSH"
                        onClick={this.commentAchievement}
                      />
                      <Button
                        color="blue"
                        content="댓글작성(문자)"
                        name="SMS"
                        onClick={this.commentAchievement}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeCommentModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="small"
                    open={deleteAndPassCheckedModalOpen}
                    onClose={this.closeDeleteAndPassCheckedModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>
                    <Modal.Header>인증샷 일괄 삭제 및 인증처리</Modal.Header>
                    <Modal.Content>
                      총 {checkedAchievementFileIds.length}개의 인증샷 삭제 후
                      인증 처리를 진행하시겠습니까?
                      <br />
                      {user.achievements
                        .filter((a) =>
                          checkedAchievementFileIds.includes(
                            a.achievementFile.id,
                          ),
                        )
                        .map((a) => {
                          return (
                            <div key={a.id} style={{ marginBottom: 10 }}>
                              <Image
                                size={'small'}
                                src={a.achievementFile.url}
                              />
                              {a.id} / {a.achievementFile.id}
                            </div>
                          );
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="삭제(성공처리)"
                        onClick={this.deleteAndPassCheckedImages}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeDeleteAndPassCheckedModal}
                      />
                    </Modal.Actions>
                  </Modal>
                  <UploadUrlAchievementModal
                    achievement={achievement}
                    isOpened={uploadUrlModalOpen}
                    onClosePress={this.closeUploadUrlModal}
                    onUploadPress={(achievement) =>
                      this.uploadAchievementUrl(achievement)
                    }
                  />
                  <Modal
                    size="tiny"
                    open={uploadModalOpen}
                    onClose={this.closeUploadModal}
                  >
                    <Modal.Header>인증샷 업로드</Modal.Header>
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <Form.Group inline>
                            <Form.Field
                              control={Radio}
                              label="성공 처리"
                              value="success"
                              name="selectImage"
                              checked={selectImage === 'success'}
                              onChange={this.handleSelectImageChange}
                            />
                            <Form.Field
                              control={Radio}
                              label="개별 선택"
                              value="custom"
                              name="selectImage"
                              checked={selectImage === 'custom'}
                              onChange={this.handleSelectImageChange}
                            />
                          </Form.Group>
                          <span style={{ color: 'red' }}>
                            *100% 달성 시, 예치금과 상금(페이백) 모두 지급되는
                            처리방법입니다.
                          </span>
                        </Form.Field>
                        <Form.Field disabled={selectImage === 'success'}>
                          <label>인증샷 이미지</label>
                          <input
                            name={'url'}
                            type="file"
                            onChange={this.handleChangeSingleFile}
                          />
                        </Form.Field>
                        <Form.Field>
                          <label>인증샷 url</label>
                          <input
                            name="url"
                            value={achievement.url}
                            onChange={this.handleAchievement}
                          />
                        </Form.Field>
                        <Image src={achievement.url} size="medium" />
                        <Form.Field>
                          <label>제출시간</label>
                          <input
                            name="submitDatetime"
                            value={achievement.submitDatetime}
                            onChange={this.handleAchievement}
                          />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="업로드"
                        onClick={this.uploadAchievementFile}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeUploadModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={adminCommentModalOpen}
                    onClose={this.closeDeleteModal}
                  >
                    <Modal.Header>공식 댓글 정보</Modal.Header>
                    <Modal.Content>
                      {adminComments &&
                        adminComments.map((comment, idx) => (
                          <p key={idx}>
                            {moment(comment.createdAt).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )}
                            <br />
                            {comment.comment}
                          </p>
                        ))}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeAdminCommentModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={reportModalOpen}
                    onClose={this.closeReportModal}
                  >
                    <Modal.Header>신고 정보</Modal.Header>
                    <Modal.Content>
                      {reports &&
                        reports.map((ru) => {
                          return (
                            <div key={ru.id}>
                              {ru.id} / {ru.name} / {ru.nickname} /{' '}
                              {ru.reportReason} / {ru.comment}
                              <br />
                            </div>
                          );
                        })}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeReportModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={heartModalOpen}
                    onClose={this.closeHeartModal}
                  >
                    <Modal.Header>인증패스 처리하기</Modal.Header>
                    <Modal.Content>
                      <p>
                        [{achievement.id}] 인증카드에 인증패스 처리를
                        하시겠습니까?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="사용"
                        onClick={this.useHeart}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeHeartModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Modal
                    size="tiny"
                    open={heartCancelModalOpen}
                    onClose={this.closeHeartCancelModal}
                  >
                    <Modal.Header>하트 사용 취소하기</Modal.Header>
                    <Modal.Content>
                      <p>
                        [{achievement.id}] 인증카드에 사용한 하트를
                        취소하시겠습니까?
                      </p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="취소하기"
                        onClick={this.cancelHeart}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeHeartCancelModal}
                      />
                    </Modal.Actions>
                  </Modal>
                  <AllAchievementUrlsModal
                    isOpened={allAchievementUrlsModalOpen}
                    onClosePress={this.closeAllAchievementUrlsModal}
                    authentications={authentications}
                    allAchievementFiles={allAchievementFiles}
                  />
                  <AllAchievementModal
                    isOpened={allAchievementFilesModalOpen}
                    onClosePress={this.closeAllAchievementFilesModal}
                    allAchievementFiles={allAchievementFiles}
                    authentications={authentications}
                  />

                  <Modal
                    size="tiny"
                    open={switchModalOpen}
                    onClose={this.closeSwitchModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>
                    <Modal.Header>인증샷 교체</Modal.Header>
                    <Modal.Content>
                      <p>두 인증카드의 인증샷을 교체하시겠습니까?</p>
                      {dragItem && dropItem && (
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 2 }}>
                            <Image fluid src={dragItem.achievementFile.url} />
                            <p>{dragItem.challenge.title}</p>
                            <p>
                              {dragItem.id} / {dragItem.achievementFile.id}
                            </p>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              textAlign: 'center',
                              fontSize: 30,
                            }}
                          >
                            ↔
                          </div>
                          <div style={{ flex: 2 }}>
                            <Image fluid src={dropItem.achievementFile.url} />
                            <p>{dropItem.challenge.title}</p>
                            <p>
                              {dropItem.id} / {dropItem.achievementFile.id}
                            </p>
                          </div>
                        </div>
                      )}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="교체"
                        onClick={this.switchAchievementFiles}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closeSwitchModal}
                      />
                    </Modal.Actions>
                  </Modal>

                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  {challengeCompletedMounted && (
                    <ChallengeInfo
                      user={userChallengesCompleted}
                      title={'상금 수령 완료한 챌린지 참가이력'}
                      challengeAccordion={challengeAccordion}
                      challengeAccordionAllOpen={challengeAccordionAllOpen}
                      showCancelModal={this.showCancelModal}
                      showReviewModal={this.showReviewModal}
                      showDeleteModal={this.showDeleteModal}
                      showCommentModal={this.showCommentModal}
                      showUploadModal={this.showUploadModal}
                      showUploadUrlModal={this.showUploadUrlModal}
                      showAdminCommentModal={this.showAdminCommentModal}
                      showAllAchievementFilesModal={
                        this.showAllAchievementFilesModal
                      }
                      showAllAchievementUrlsModal={
                        this.showAllAchievementUrlsModal
                      }
                      showReportModal={this.showReportModal}
                      handleChallengeAccordion={this.handleChallengeAccordion}
                      changeChallengeAccordionAllOpen={
                        this.changeChallengeAccordionAllOpen
                      }
                    />
                  )}
                  {challengeCompletedLoading && (
                    <Loader active inline="centered" />
                  )}
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <RetryChallengeInfo retries={retries} />
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <CartInfo carts={carts} deleteCart={this.deleteCart} />
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <AbusingInfo user={user} />
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <AccountInfo
                    user={user}
                    handleCheckbox={this.handleCheckbox}
                    handleCardAmount={this.handleCardAmount}
                    handleTransferAmount={this.handleTransferAmount}
                    cancelCard={this.cancelCard}
                    withdraw={this.withdraw}
                    checkPgIds={checkPgIds}
                    checkTransferIds={checkTransferIds}
                    bankOptions={bankOptions}
                    handleBankChange={this.handleBankChange}
                    handleAccountChange={this.handleAccountChange}
                    withdrawPrize={this.withdrawPrize}
                    withdrawPrizeSum={withdrawPrizeSum}
                  />
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                  <Grid columns="equal">
                    <Grid.Column>
                      <Accordion>
                        <Accordion.Title
                          active={transactionToggleOpen}
                          index={4}
                          onClick={() =>
                            this.setState({
                              transactionToggleOpen: !transactionToggleOpen,
                            })
                          }
                        >
                          <Header as="h5">
                            <Icon name="dropdown" />
                            거래 내역 조회
                          </Header>
                        </Accordion.Title>
                        <Accordion.Content active={transactionToggleOpen}>
                          <TransactionList
                            allTransactions={allTransactions}
                            validated={validated}
                            showValidationModal={this.showValidationModal}
                          />
                        </Accordion.Content>
                      </Accordion>
                      <ValidationRequestModal
                        validationModalOpen={validationModalOpen}
                        closeValidationModal={this.closeValidationModal}
                        setValidated={this.setValidated}
                        location={'유저 정보'}
                        defaultValue={
                          this.props.defaultText ??
                          '유저 거래내역 자료요청 대응'
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Accordion>
                        <Accordion.Title
                          active={activeIndex === 5}
                          index={5}
                          onClick={this.handleAccordionClick}
                        >
                          <Header as="h5">
                            <Icon name="dropdown" />
                            결제건 별 잔액 현황
                          </Header>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 5}>
                          <RemainBalancePerTransaction
                            cashTransactions={cashTransactions}
                          />
                        </Accordion.Content>
                      </Accordion>
                    </Grid.Column>
                  </Grid>
                  <Divider hidden />
                  <Divider hidden />
                  <Divider hidden />
                </Tab.Pane>
              ),
            },
            {
              menuItem: '랜선대회',
              render: () => (
                <Tab.Pane
                  onClick={() => this.setState({ activeTab: 'RACE' })}
                  attached={true}
                >
                  <div>
                    <RaceOrder userId={user.id} />
                  </div>
                </Tab.Pane>
              ),
            },
            {
              menuItem: '스토어',
              render: () => (
                <Tab.Pane
                  onClick={() => this.setState({ activeTab: 'STORE' })}
                  attached={true}
                >
                  <div>
                    <PillOrder userId={user.id} />
                    <ActivatedRupInfo userId={user.id} />
                    <StorePointTransaction userId={user.id} />
                  </div>
                </Tab.Pane>
              ),
            },
            {
              menuItem: '세일즈 부스터',
              render: () => (
                <Tab.Pane
                  onClick={() => this.setState({ activeTab: 'SALES_BOOSTER' })}
                  attached={true}
                >
                  <PurchaseChallengeInfo
                    title={'현재 진행중인 챌린지 참가이력'}
                    purchaseChallengeParticipants={
                      purchaseChallengeParticipants
                    }
                    showCancelModal={this.showPurchaseChallengeCancelModal}
                  />
                  <Modal
                    size="tiny"
                    open={cancelPurchaseChallengeModalOpen}
                    onClose={this.closePurchaseChallengeCancelModal}
                  >
                    <Dimmer active={isLoading}>
                      <Loader />
                    </Dimmer>
                    <Modal.Header>챌린지 신청 취소</Modal.Header>
                    <Modal.Content>
                      {!_.isEmpty(participant) && (
                        <p>{`[${participant.id}] ${participant.title}`}</p>
                      )}
                      <p>위의 챌린지 신청을 취소 하시겠습니까?</p>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="blue"
                        content="신청취소"
                        onClick={this.registerCancelPurchaseChallenge}
                      />
                      <Button
                        color="black"
                        content="닫기"
                        onClick={this.closePurchaseChallengeCancelModal}
                      />
                    </Modal.Actions>
                  </Modal>
                </Tab.Pane>
              ),
            },
          ]}
        />

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </div>
    );
  }
}

export default UserDetail;
